import React, { useContext, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import DropDownMenu, { Dropdown, Menu } from "../components/common/sidebarMenu";
import { UserContext } from '../context/theme';



function Header() {
    const { reset, counts } = useContext(UserContext)
    return (
        <>
            <div className="left-sidebar-wrapper">
                <nav className="sidebar">
                    <div className="sidebar-header text-center">
                        <NavLink activeclassname="isActive" to="/"><img src='/assets/images/logo.png' /></NavLink>
                        <strong><NavLink activeclassname="isActive" to='/'>FD</NavLink></strong>
                    </div>
                    <div className="left-nav-menu">
                        <DropDownMenu>
                            <Menu path="/" title="Dashboard" icon="ic:baseline-dashboard" iconifyIcon="true"></Menu>
                            <Dropdown title={`Master`} icon="tabler:category" iconifyIcon="true">
                                <Menu path="/product/category/1" title={`Product Category`}></Menu>
                                <Menu path="/product/sub-category/1" title={`Product Sub Category`} ></Menu>
                                <Menu path="/service/category/1" title={`Service Category`}></Menu>
                                <Menu path="/service/sub-category/1" title={`Service Sub Category`}></Menu>
                                <Menu path="/product/unit/1" title={`Product Unit`}></Menu>
                                <Menu path="/service/unit/1" title={`Service Unit`}></Menu>
                                <Menu path="/gst/1" title="GST" ></Menu>
                            </Dropdown>
                            <Menu path="/customers" title={`Customer`} icon="mdi:user" iconifyIcon="true"></Menu>
                            <Dropdown title={`Vendor`} icon="mdi:user-group" iconifyIcon="true">
                                <Menu path="/vendor" title={`Verified Vendor`}></Menu>
                                <Menu path="/vendor/unverified" title={`Unverified Vendor`}></Menu>
                            </Dropdown>
                            <Dropdown title={`Store Management`} icon="fluent-mdl2:product-list" iconifyIcon="true">
                                <Menu path="/store/product" title="Products" />
                                <Menu path="/store/service" title="Services" />
                                
                            </Dropdown>
                            <Dropdown title={`Subscription`} icon="bxs:offer" iconifyIcon="true">
                                <Menu path="/subscription/1" title="Plans" />
                                <Menu path="/subscription/orders/1" title="Purchase History" />
                            </Dropdown>
                            <Dropdown title={`Manage Orders`} icon="fluent-mdl2:product-variant" iconifyIcon="true">
                                <Menu path="/manage-orders/orders" title="Orders" />
                                <Menu path="/bulk-inquiry/1" title="Bulk Order Inquiry" />
                            </Dropdown>
                            <Dropdown title={`Top Spot`} icon="mdi:user" iconifyIcon="true">
                                <Menu path="/top-spot" title="For Product/Service" />
                                <Menu path="/top-spot/vendor" title="For Vendor" />
                            </Dropdown>

                            <Menu path="/manage-account/1" title="Manage Account" icon="grommet-icons:transaction" iconifyIcon="true" />
                            {/* <Menu path="/top-spot" title={`Top Spot`} icon="mdi:user" iconifyIcon="true"></Menu> */}
                            {/* <Menu path="/settlements/1" title="Settlements" icon="icon-park-outline:transaction" iconifyIcon="true" /> */}
                            <Dropdown title={`Insight`} icon="fluent-mdl2:insights" iconifyIcon="true">
                                <Menu path="/search-history/1" title="Search Insight" />
                                <Menu path="/whatsapp-insight/1" title="WhatsApp Insight"/>
                                <Menu path="/store/inquiry" title="Store Contacted" />
                                <Menu path="/store/inquiry/products" title="Products Enquiry" />
                                <Menu path="/store/inquiry/services" title="Service Contacted" />
                            </Dropdown>
                            <Menu path="/promocode/1" title="Promocode" icon="ant-design:setting-outlined" iconifyIcon="true" />

                            <Dropdown title={`Slider`} icon="bx:slider-alt" iconifyIcon="true">
                                <Menu path="/product/slider/1" title={`Product Slider`}></Menu>
                                <Menu path="/service/slider/1" title={`Service Slider`}></Menu>
                                <Menu path="/vendor/slider/1" title={`Vendor Slider`}></Menu>
                            </Dropdown>


                            <Dropdown title="Faqs" icon="wpf:faq" iconifyIcon="true">
                                <Menu path="/faqs/customer/1" title="For Customer" />
                                <Menu path="/faqs/vendor/1" title="For Vendor" />
                            </Dropdown>
                            <Dropdown title="Information" icon="material-symbols:menu-book-sharp" iconifyIcon="true">

                                <Menu path="/information/terms" title="Terms & Conditions" />
                                <Menu path="/information/privacy" title="Privacy Policy" />
                                <Menu path="/information/about" title="About Us" />
                                <Menu path="/information/delivery-note" title="Delivery Note" />
                                <Menu path="/contact/1" title="Contact Us"></Menu>

                            </Dropdown>
                            <Menu path="/change-password" title="Change Password" icon="teenyicons:password-solid" iconifyIcon="true" />

                            <Menu path="/settings" title="Settings" icon="ant-design:setting-outlined" iconifyIcon="true" />
                            <Menu path="/version" title="Versions" icon="mingcute:version-line" iconifyIcon="true" />
                            <Menu path="/helpdesk/list/1" title="Helpdesk" icon="mdi:comment-help" iconifyIcon="true" />


                        </DropDownMenu>


                        <ul>
                            <li>
                                <a title="Logout" onClick={() => reset()}>
                                    <span className="icon-log-out"></span>
                                    <span className="menu_txt">Logout</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                    {/* <div className="version">
                    <span className="vs-mobile">LT-M-22</span>
                    <span className="vs-web">V LT-MAR-22</span>
                </div> */}
                </nav>
            </div>
        </>
    );
}
export default Header;